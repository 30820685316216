import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import { Icon } from '@just-ai/just-ui';

import { t } from 'localization';

import { isChatGptEnabled } from 'modules/TemplatesWizard/access';

import { BASE_WIZARD_PATH } from '../../shared/consts';
import ChatGPTTooltip from '../wizard-skill-card/ChatGPTTooltip';
import { WizardStartCard, WizardStartCardBody, WizardStartImage, WizardStartLabel } from './wisard-start-card';
import classes from './wizard-start.module.scss';

type StartCardType = {
  icon: string;
  type: string;
  labels: { background: string; icon: string; text: string; id?: string }[];
  title: string;
  list: string[];
  link: string;
};

const START_CARDS = (isCallBot: boolean): StartCardType[] => [
  {
    icon: 'falPager',
    type: 'Wizard',
    labels: [
      {
        background: 'var(--green-600)',
        icon: 'farThumbsUp',
        text: t('StartCard:Wizard:label'),
      },
      !isChatGptEnabled()
        ? null
        : {
            background:
              'linear-gradient(95.11deg, #CAE198 -0.64%, #46C1AB 19.28%, #4295E0 49.16%, #7D5ADE 75.36%, #C81EB0 100%)',
            text: t('StartCard:Wizard:ChatGPT:label'),
            id: 'chatGPTLabel',
          },
    ].filter(Boolean) as StartCardType['labels'],
    title: t('StartCard:WizardTitle'),
    list: [t('StartCard:Wizard:li-item-1'), t('StartCard:Wizard:li-item-2'), t('StartCard:Wizard:li-item-3')],
    link: BASE_WIZARD_PATH + (isCallBot ? '/calls-type-select' : '/skill'),
  },
  {
    icon: 'falNetworkWired',
    type: 'Constructor',
    labels: [
      {
        background: 'var(--indigo-600)',
        icon: 'farUserGraduate',
        text: t('StartCard:Graph:label'),
      },
      !isChatGptEnabled()
        ? null
        : {
            background:
              'linear-gradient(95.11deg, #CAE198 -0.64%, #46C1AB 19.28%, #4295E0 49.16%, #7D5ADE 75.36%, #C81EB0 100%)',
            text: t('StartCard:Wizard:ChatGPT:label'),
            id: 'chatGPTLabel',
          },
    ].filter(Boolean) as StartCardType['labels'],
    title: t('StartCard:GraphTitle'),
    list: [t('StartCard:Graph:li-item-1'), t('StartCard:Graph:li-item-2'), t('StartCard:Graph:li-item-3')],
    link: BASE_WIZARD_PATH + (isCallBot ? '/calls-type-select' : '/aimygraph') /*'/graph-choose'*/,
  },
];

export const WizardStart = () => {
  const location = useLocation<{
    isCallBot?: boolean;
  }>();

  const isCallBot = useMemo(() => {
    return location?.state?.isCallBot || false;
  }, [location?.state]);

  const CARDS = useMemo(() => START_CARDS(isCallBot), [isCallBot]);

  return (
    <div className={classes.WizardStartContainer}>
      <h1>{t('WizardStartPage:TitleH1')}</h1>
      <div className={classes.WizardStart}>
        {CARDS.map(card => {
          return (
            <WizardStartCard key={card.icon} link={card.link} type={card.type}>
              <WizardStartImage>
                <Icon name={card.icon} color='primary' size='3x' />

                <div className={classes.WizardLabelContainer}>
                  {card.labels.map(label => (
                    <WizardStartLabel
                      id={label.id}
                      key={label.text}
                      icon={label.icon}
                      background={label.background}
                      text={label.text}
                    />
                  ))}
                </div>
              </WizardStartImage>
              <WizardStartCardBody title={card.title} list={card.list} />
            </WizardStartCard>
          );
        })}
        {isChatGptEnabled() && <ChatGPTTooltip targetId='chatGPTLabel' />}
      </div>
    </div>
  );
};
