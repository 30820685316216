import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Component } from 'react';
import './style.scss';
import classNames from 'classnames';
export class SimpleSelect extends Component {
    constructor(props) {
        super(props);
        this.onChoose = (value) => {
            this.setState({ stateValue: value });
            this.onChange(value);
        };
        this.onChange = (value) => {
            if (this.props.onChange) {
                this.props.onChange(value);
            }
        };
        this.onKeyDown = (event) => {
            const { keyCode, target } = event;
            const { previousElementSibling, nextElementSibling } = target;
            switch (keyCode) {
                case 27:
                    return target.blur();
                case 37:
                    return previousElementSibling && previousElementSibling.focus();
                case 39:
                    return nextElementSibling && nextElementSibling.focus();
            }
        };
        this.state = { stateValue: props.defaultValue };
    }
    render() {
        const { className, options, name, value } = this.props;
        const { stateValue } = this.state;
        const valueToCompare = value === undefined ? stateValue : value;
        return (_jsxs("div", { className: classNames('justui_simpleSelect__container', className), children: [_jsx("input", { type: 'hidden', name: name, value: valueToCompare }), options.map(({ value, label }, index) => (_jsx("button", { "data-test-id": `SimpleSelect.Button.${value}`, style: { left: (index - 1) * -1 }, onKeyDown: this.onKeyDown, className: classNames('justui_simpleSelect__button', 'justui_simpleSelect__primary', valueToCompare === value && 'active'), onClick: this.onChoose.bind(null, value), type: 'button', children: label }, `${value}_${index}`)))] }));
    }
}
export default SimpleSelect;
