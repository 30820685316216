import React, { useCallback, useRef, useEffect, useState, useMemo } from 'react';
import { debounce, isEmpty, isEqual, cloneDeep, mergeWith } from 'lodash';
import { useForceUpdate, useToggle } from '@just-ai/just-ui';
import { FAQApi, IntentsApi, FaqQuestionData } from '@just-ai/api/dist/generated/Caila';

import { t } from 'localization';
import { useAppSelector } from 'storeHooks';

import { RootState } from 'storeTypes';

import diffInArrays, { buildEqualFn } from 'helpers/array/diffInArrays';
import { FAQWithChatGPTSkillConfig } from 'modules/TemplatesWizard/types';
import SkillCollapseContainer from 'modules/TemplatesWizard/primitives/SkillCollapseContainer';
import TextArea from 'modules/TemplatesWizard/FieldBuilder/fields/components/TextArea';
import { isChatGptEnabled } from 'modules/TemplatesWizard/access';
import DataCollectionTable, {
  DataCollectionTableProps,
} from 'modules/TemplatesWizard/FieldBuilder/fields/components/DataCollectionTable';
import { combineMerge } from 'modules/TemplatesWizard';
import ChatGPTBanner from 'components/ChatGPTBanner';

import TextMessage from '../../FieldBuilder/fields/components/TextMessage';
import { BaseSkill, InitSkillArgs, ViewComponentProps } from '../BaseSkill';
import BotSettings, { ConversationTone, EmojiUsing } from './BotSettings';
import FaqWithChatGPTQuestions, { FAQQuestionsRow } from './FaqWithChatGPTQuestions';

import styles from './styles.module.scss';

type FAQState = {
  questions: FAQQuestionsRow[];
  botTone: ConversationTone;
  botEmojiUsing: EmojiUsing;
  companyNameAndDescription: string;
  contacts?: Record<string, any>[];
};

const getStaticSuggests = () => [
  {
    question: t('FAQSkill:StaticSuggests:question1'),
    answerPlaceholder: t('FAQSkill:StaticSuggests:answerPlaceholder1'),
  },
  {
    question: t('FAQSkill:StaticSuggests:question2'),
    answerPlaceholder: t('FAQSkill:StaticSuggests:answerPlaceholder2'),
  },
  {
    question: t('FAQSkill:StaticSuggests:question3'),
    answerPlaceholder: t('FAQSkill:StaticSuggests:answerPlaceholder3'),
  },
  {
    question: t('FAQSkill:StaticSuggests:question4'),
    answerPlaceholder: t('FAQSkill:StaticSuggests:answerPlaceholder4'),
  },
  {
    question: t('FAQSkill:StaticSuggests:question5'),
    answerPlaceholder: t('FAQSkill:StaticSuggests:answerPlaceholder5'),
  },
  {
    question: t('FAQSkill:StaticSuggests:question6'),
    answerPlaceholder: t('FAQSkill:StaticSuggests:answerPlaceholder6'),
  },
  {
    question: t('FAQSkill:StaticSuggests:question7'),
    answerPlaceholder: t('FAQSkill:StaticSuggests:answerPlaceholder7'),
  },
];

const getDefaultRows = () =>
  [
    {
      question: t('FAQSkill:StaticSuggests:question1'),
      answer: '',
    },
  ] as FAQQuestionsRow[];

const getFaqTitleTextBlock = () => ({
  title: t('FAQWithChatGPTSkill:Fields:Title'),
  description: '',
});
const getCompanyInfoTextBlock = () => ({
  title: t('FAQSkill:Fields:CompanyInfo:Title'),
  description: '',
});
const getQuestionsTextBlock = () => ({
  title: t('FAQSkill:Fields:Questions:Title'),
  description: t('FAQSkill:Fields:Questions:Description'),
});

const getContactsTable = (): DataCollectionTableProps['info'] => ({
  title: '',
  allowNewRows: true,
  preventDeleteLast: true,
  preventDeleteLastHint: t('SkillsWizard:PreventDelete:Hit'),
  columns: [
    {
      envName: 'name',
      header: t('FAQSkill:Contacts:Header'),
      type: 'select',
      selectOptions: [
        t('FAQSkill:Contacts:Columns:Email'),
        t('FAQSkill:Contacts:Columns:Site'),
        t('FAQSkill:Contacts:Columns:Vkontakte'),
        t('FAQSkill:Contacts:Columns:Odnoklassniki'),
        t('FAQSkill:Contacts:Columns:Telegram'),
      ],
      selectMultiple: false,
      eachRowSelectUniqValue: true,
      arbitrarySelectOptions: true,
      placeholder: '',
    },
    {
      envName: 'value',
      header: '',
      type: 'text',
      selectOptions: [],
      placeholder: '',
    },
  ],
  addRowText: t('FAQSkill:Contacts:AddRowText'),
  maxRows: 10,
});

type ErrorValidationType<ELEMENT> = {
  companyNameAndDescription?: ELEMENT;
  contacts?: Record<string, ELEMENT>;
  questions?: Record<string, ELEMENT>;
};

type ValidationResponse = { errors: ErrorValidationType<string>; urgentErrors: ErrorValidationType<boolean> };
const validate = (data: FAQState): ValidationResponse => {
  const errors: ErrorValidationType<string> = {};
  const urgentErrors: ErrorValidationType<boolean> = {};
  if (!data.companyNameAndDescription) {
    errors['companyNameAndDescription'] = t('FAQSkill:CompanyNameAndDescription:HelperText');
  } else if (data.companyNameAndDescription.length > 1000) {
    errors['companyNameAndDescription'] = t('FAQSkill:CompanyNameAndDescription:HelperText');
    urgentErrors['companyNameAndDescription'] = true;
  }

  if (data.contacts) {
    const contactsErrors: any = {};
    const urgentContactsErrors: any = {};
    for (let [index, el] of Object.entries(data.contacts)) {
      if (!el.name.trim()) {
        contactsErrors[`${index}.name`] = t('FAQSkill:Empty');
      } else if (el.name.trim().length > 20) {
        contactsErrors[`${index}.name`] = t('FAQSkill:MaxLength', 20);
        urgentContactsErrors[`${index}.name`] = true;
      }
      if (!el.value.trim()) {
        contactsErrors[`${index}.value`] = t('FAQSkill:Empty');
      } else if (el.value.trim().length > 100) {
        contactsErrors[`${index}.value`] = t('FAQSkill:MaxLength', 100);
        urgentContactsErrors[`${index}.value`] = true;
      }
    }
    if (!isEmpty(contactsErrors)) {
      errors['contacts'] = contactsErrors;
    }
    if (!isEmpty(urgentContactsErrors)) {
      urgentErrors['contacts'] = urgentContactsErrors;
    }
  }
  if (data.questions) {
    const questionsErrors: any = {};
    const urgentQuestionsErrors: any = {};
    for (let [index, el] of Object.entries(data.questions)) {
      if (!el.question.trim()) {
        questionsErrors[`${index}.question`] = t('FAQSkill:Empty');
      } else if (el.question.trim().length > 100) {
        questionsErrors[`${index}.question`] = t('FAQSkill:MaxLength', 100);
        urgentQuestionsErrors[`${index}.question`] = true;
      }
      if (!el.answer.trim()) {
        questionsErrors[`${index}.answer`] = t('FAQSkill:Empty');
      } else if (el.answer.trim().length > 200) {
        questionsErrors[`${index}.answer`] = t('FAQSkill:MaxLength', 200);
        urgentQuestionsErrors[`${index}.answer`] = true;
      }
    }
    if (!isEmpty(questionsErrors)) {
      errors['questions'] = questionsErrors;
    }
    if (!isEmpty(urgentQuestionsErrors)) {
      urgentErrors['questions'] = urgentQuestionsErrors;
    }
  }
  return { errors, urgentErrors };
};

interface FaqSkillProps extends ViewComponentProps {
  skill: FaqWithChatGPT;
}
const FaqSkillView = React.memo(({ skill, index }: FaqSkillProps) => {
  const { currentUser } = useAppSelector((store: RootState) => ({
    currentUser: store.CurrentUserReducer.currentUser,
  }));
  const forceUpdate = useForceUpdate();
  useEffect(() => skill.subscribe('forceUpdate', forceUpdate), [skill, forceUpdate]);
  const [isShowErrors, showError, hideError] = useToggle(false);
  const [validationResponse, setValidationResponse] = useState<ValidationResponse>(() => ({
    errors: {},
    urgentErrors: {},
  }));

  const onValidate = useCallback(() => {
    const isValid = skill.isValid;
    !isValid ? showError() : hideError();
    return isValid;
  }, [hideError, showError, skill]);

  const onChangeInner = useCallback(
    async (state: FAQState) => {
      innerState.current = state;
      skill.notify('onFieldsChange', { value: innerState.current, isDefaultValue: false });

      const isDeleteCurrentlyDisable = deleteDisable.current;
      deleteDisable.current = innerState.current.questions.length === 1;
      if (isDeleteCurrentlyDisable !== deleteDisable.current) {
        forceUpdate();
      }

      const allDefaultValues = cloneDeep(defaultValues.current.questions);
      const res = mergeWith(allDefaultValues, innerState.current.questions, combineMerge);
      skill.syncIntentsDebounced(res);

      const validationRes = await validate({ ...innerState.current, questions: res });
      setValidationResponse(prevErrors => (isEqual(prevErrors, validationRes) ? prevErrors : validationRes));
      skill.isValid = isEmpty(validationRes.errors);
      if (skill.isValid) hideError();
    },
    [forceUpdate, hideError, skill]
  );

  const defaultValues = useRef<FAQState>({
    questions: [],
    botTone: ConversationTone.NEUTRAL,
    botEmojiUsing: EmojiUsing.RARELY,
    companyNameAndDescription: '',
  });
  const deleteDisable = useRef(false);
  const innerState = useRef<FAQState>(!isEmpty(skill.initialValues) ? skill.initialValues : defaultValues);

  const onChangeQuestions = useCallback(
    (data: FAQQuestionsRow[], isDefault: boolean) => {
      if (isDefault) {
        defaultValues.current.questions = data;
        skill.notify('onFieldsChange', { value: defaultValues.current, isDefaultValue: true });

        const allDefaultValues = cloneDeep(defaultValues.current.questions);
        const res = mergeWith(allDefaultValues, innerState.current.questions, combineMerge);
        skill.syncIntentsDebounced(res);
        return;
      }
      const newState = { ...innerState.current, questions: data };
      onChangeInner(newState);
    },
    [skill, onChangeInner]
  );

  const initialContacts = useMemo(() => {
    return (
      innerState.current.contacts ?? [
        {
          name: t('FAQSkill:Contacts:Columns:Email'),
          value: currentUser.email || '',
        },
      ]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeStateParam = useCallback(
    (value: any, name: string, isDefault: boolean) => {
      if (isDefault) return;
      onChangeInner({ ...innerState.current, [name]: value });
    },
    [onChangeInner]
  );

  const companyNameAndDescriptionInfo = useMemo(() => {
    return {
      title: t('FAQSkill:CompanyNameAndDescription:Title'),
      placeholder: '',
      helperText: t('FAQSkill:CompanyNameAndDescription:HelperText'),
    };
  }, []);

  return (
    <SkillCollapseContainer skill={skill} onValidate={onValidate} index={index} wrapperClassName={styles.FaqSkill}>
      <div style={{ marginTop: 8 }} dangerouslySetInnerHTML={{ __html: skill.skillConfig.description }} />
      {isChatGptEnabled() && <ChatGPTBanner showOnlyLimitExceeded />}
      <TextMessage info={getFaqTitleTextBlock()} />
      <BotSettings
        onChange={state => {
          onChangeInner({ ...innerState.current, ...state });
        }}
        value={innerState.current}
      />
      <TextMessage info={getCompanyInfoTextBlock()} />
      <TextArea
        value={innerState.current.companyNameAndDescription}
        envName='companyNameAndDescription'
        onChange={onChangeStateParam}
        isShowErrors={isShowErrors || validationResponse.urgentErrors['companyNameAndDescription']}
        errors={validationResponse.errors}
        info={companyNameAndDescriptionInfo}
      />
      <DataCollectionTable
        envName='contacts'
        info={getContactsTable()}
        isShowErrors={isShowErrors}
        errors={validationResponse.errors}
        urgentErrors={validationResponse.urgentErrors}
        value={initialContacts}
        onChange={onChangeStateParam}
      />

      <TextMessage info={getQuestionsTextBlock()} />
      {skill.isInitiated && (
        <FaqWithChatGPTQuestions
          questionPlaceholder={t('FAQSkill:QuestionPlaceholder')}
          answerPlaceholder={t('FAQSkill:AnswerPlaceholder')}
          suggests={getStaticSuggests()}
          rows={innerState.current.questions}
          errors={validationResponse.errors['questions']}
          urgentErrors={validationResponse.urgentErrors['questions']}
          isNeedToShowError={isShowErrors}
          maxRows={5}
          deleteDisable={deleteDisable.current}
          addRowText={t('FAQSkill:Contacts:AddRowText')}
          onChange={onChangeQuestions}
        />
      )}
    </SkillCollapseContainer>
  );
});

export class FaqWithChatGPT extends BaseSkill {
  private faqApi: FAQApi;
  private intentsApi: IntentsApi;
  public static knowledgeBaseName = 'FAQ.SkillsWizard';
  public static rootKnowledgeBasePath = `/KnowledgeBase/${this.knowledgeBaseName}`;
  private knowledgeBaseQuestions: FaqQuestionData[] = [];
  public isInitiated = false;

  constructor(public skillConfig: FAQWithChatGPTSkillConfig) {
    super(skillConfig);
    this.faqApi = new FAQApi({ basePath: '/cailapub' });
    this.intentsApi = new IntentsApi({ basePath: '/cailapub' });
  }

  onMount = async () => {
    this.notify('loading', true);
    await this.initKnowledgeBase().finally(() => {
      this.forceUpdate();
      this.notify('loading', false);
    });
  };

  override init(args: InitSkillArgs) {
    super.init(args);
    this.initialValues ??= {
      questions: getDefaultRows(),
      botTone: ConversationTone.NEUTRAL,
      botEmojiUsing: EmojiUsing.RARELY,
      companyNameAndDescription: '',
      contacts: undefined,
    } as FAQState;
  }

  initKnowledgeBase = async () => {
    if (!this.accountId || !this.projectId) return;
    const { data: rootIntents } = await this.intentsApi.searchIntents(this.accountId, this.projectId, {
      path: FaqWithChatGPT.rootKnowledgeBasePath,
    });
    if (rootIntents.length > 0 && rootIntents.find(intent => intent.path === FaqWithChatGPT.rootKnowledgeBasePath)) {
      await this.getAll();
      this.isInitiated = true;
      return;
    }
    await this.intentsApi.createIntent(this.accountId, this.projectId, {
      path: FaqWithChatGPT.rootKnowledgeBasePath,
    });
    this.isInitiated = true;
  };

  getAll = async () => {
    if (!this.accountId || !this.projectId) return;
    const { data: questions } = await this.faqApi.getFaqQuestionList(
      this.accountId,
      this.projectId,
      FaqWithChatGPT.rootKnowledgeBasePath
    );
    this.knowledgeBaseQuestions = questions.filter(
      question => question.intent.path !== FaqWithChatGPT.rootKnowledgeBasePath
    );
  };

  syncIntents = async (questions: FAQQuestionsRow[]) => {
    if (!this.accountId || !this.projectId) return;
    const newIntents = questions
      .filter(question => question.question)
      .map(question => ({
        intent: {
          path: this.getQuestionFullPath(question.question),
          phrases: [{ text: question.question }],
        },
        replies: [{ type: 'text', text: question.answer, markup: 'plain' }],
      })) as FaqQuestionData[];

    const { toDelete, toCreate, updateDiff } = diffInArrays(
      newIntents,
      this.knowledgeBaseQuestions,
      buildEqualFn(['intent.path']),
      buildEqualFn(['replies[0].text'])
    );

    const intentToDelete = toDelete.map(question => question.intent.path).filter(Boolean) as string[];

    if (intentToDelete.length > 0) {
      await this.intentsApi.deleteMultipleByPaths(this.accountId, this.projectId, intentToDelete);
    }
    this.knowledgeBaseQuestions = [];

    if (updateDiff.length > 0) {
      await Promise.allSettled(
        updateDiff.map(([oldIntent, newIntent]) => {
          if (!this.accountId || !this.projectId) return Promise.resolve();
          return this.faqApi.updateFaqQuestion(this.accountId, this.projectId, oldIntent.id as number, {
            id: oldIntent.id,
            intent: { ...newIntent.intent, id: oldIntent.intent.id },
            replies: newIntent.replies,
          });
        })
      );
    }

    if (toCreate.length > 0) {
      await Promise.allSettled(
        toCreate.map(question => this.faqApi.createFaqQuestion(this.accountId!, this.projectId!, question))
      );
    }
    await this.getAll();
  };

  syncIntentsDebounced = debounce((questions: FAQQuestionsRow[]) => {
    this.notify('loading', true);
    return this.syncIntents(questions).finally(() => this.notify('loading', false));
  }, 1000);

  ViewComponent = (props: ViewComponentProps) => {
    return <FaqSkillView skill={this} {...props} />;
  };

  private getQuestionFullPath(name: string) {
    return `${FaqWithChatGPT.rootKnowledgeBasePath}/Root/${name}`;
  }
}
