import store from './store';
import {
  baseAvailableTariffChannels,
  businessChannelsTypesByLang,
  channelTypesByLang,
  operatorChannelsTypes,
} from './constants/channelTypes';
import { forEach } from 'lodash';

export default function isAccess(accessList) {
  let flag = true;
  if (Boolean(accessList) && accessList.hasOwnProperty('length') && Array.isArray(accessList)) {
    for (let i = 0; i < accessList.length; i++) {
      flag = isAccessFunction(accessList[i]) || isEnabledFunction(accessList[i]) || isIncluded(accessList[i]);
      if (!flag) {
        return flag;
      }
    }
  } else {
    flag = isAccessFunction(accessList) || isEnabledFunction(accessList) || isIncluded(accessList);
    if (!flag) {
      return flag;
    }
  }
  return flag;
}

export function isSystemAccess(systemFeatureOrFeatureList) {
  const state = store.getState();

  const systemFeatures = state.AppConfigReducer.systemFeatures;

  if (Array.isArray(systemFeatureOrFeatureList)) {
    return systemFeatureOrFeatureList.every(systemFeatureName => {
      return systemFeatures.hasOwnProperty(systemFeatureName) && systemFeatures[systemFeatureName];
    });
  } else {
    return systemFeatures.hasOwnProperty(systemFeatureOrFeatureList) && systemFeatures[systemFeatureOrFeatureList];
  }
}

export function isTextCampaignEnabled() {
  return hasFeatures('text-campaign') && isSystemAccess(['outbound_messages_licensed']);
}

export function hasFeatures(...features) {
  return features.every(isEnabledFunction);
}

export function isAccessFunction(permissionName) {
  let state = store.getState();
  //TODO remove localstorage hack?
  if (permissionName === 'ALL') {
    //todo migrate this to serverside
    return true;
  }
  try {
    let allPermissions = state.CurrentUserReducer.allPermissions;
    if (allPermissions.length === 0) {
      allPermissions = JSON.parse(localStorage.CURRENT_USER).allPermissions;
    }

    let isAccess = false;
    if (!!state.AccountsReducer.loggedInAccount) {
      isAccess = allPermissions.findIndex(permission => permission.name === 'ACCOUNTS_LOGIN_READ') > -1;
    } else {
      isAccess = allPermissions.findIndex(permission => permission.name === permissionName) > -1;
    }
    return isAccess;
  } catch (e) {
    return false;
  }
}

export function isEnabledFunction(featureName) {
  //TODO remove after backend complete
  if (featureName === 'sber.whitelabel.feature') {
    return true;
  }
  try {
    const state = { ...store.getState() };
    const allFeatures =
      state.CurrentUserReducer.features.length === 0
        ? JSON.parse(localStorage.CURRENT_USER).features
        : state.CurrentUserReducer.features;
    return allFeatures.some(feature => {
      return feature.name === featureName && feature.enabled;
    });
  } catch (e) {
    return false;
  }
}

export function isIncluded(systemFeatureOrSystemFeatures) {
  const systemFeatureList =
    typeof systemFeatureOrSystemFeatures === 'string' ? [systemFeatureOrSystemFeatures] : systemFeatureOrSystemFeatures;
  try {
    const state = { ...store.getState() };
    const systemFeatures = state.AppConfigReducer.systemFeatures;

    return systemFeatureList.every(systemFeatureName => {
      return systemFeatures.hasOwnProperty(systemFeatureName) && systemFeatures[systemFeatureName];
    });
  } catch (e) {
    return false;
  }
}

export function isConfigEnabled(configName) {
  try {
    const state = { ...store.getState() };
    return state.AppConfigReducer.zenflow[configName] || false;
  } catch (e) {
    return false;
  }
}

export function isEuroInstance() {
  try {
    const state = { ...store.getState() };
    return state.AppConfigReducer.zenflow.euroInstance || false;
  } catch (e) {
    return false;
  }
}

export function isLanguagesAvailable(languages, isCallBot = false) {
  try {
    const state = { ...store.getState() };

    let systemLanguages = state.AppConfigReducer.zenflow?.availableLanguages || ['en', 'ru', 'cn'];

    if (isCallBot) {
      systemLanguages = systemLanguages.filter(language => {
        return !['pt', 'es', 'kz'].includes(language);
      });
    }
    return languages.filter(x => systemLanguages.includes(x === 'eng' ? 'en' : x));
  } catch (e) {
    return e;
  }
}

export function isLoggedIn() {
  let localStorageCurUser = null;
  try {
    localStorageCurUser = JSON.parse(localStorage.CURRENT_USER);
  } catch (e) {
    localStorageCurUser = null;
  }
  const currentUser = store.getState().CurrentUserReducer.currentUser || localStorageCurUser;
  return !!currentUser;
}

export function isPhoneVerified() {
  let localStorageCurUser = {};
  try {
    localStorageCurUser = JSON.parse(localStorage.CURRENT_USER);
  } catch (e) {
    localStorageCurUser = {};
  }
  const { currentUser } = store.getState().CurrentUserReducer;
  return Boolean((currentUser && currentUser.phoneNumber) || localStorageCurUser.phoneNumber);
}

export function isLoggedInAccount() {
  const state = { ...store.getState() };
  let loginInAccount = state.AccountsReducer.loggedInAccount;

  if (!loginInAccount) {
    try {
      loginInAccount = JSON.parse(localStorage.getItem('LOGIN_TO_ACCOUNT'));
    } catch (e) {
      loginInAccount = null;
    }
  }

  return !!loginInAccount;
}

export function isInternal() {
  // this check if the user is super Admin
  let internal = false;
  const state = { ...store.getState() };
  if (!state.CurrentUserReducer.currentUser) {
    try {
      internal = JSON.parse(localStorage.CURRENT_USER).internal;
    } catch (e) {
      internal = state.CurrentUserReducer.internal;
    }
  } else {
    internal = state.CurrentUserReducer.internal;
  }
  return internal;
}

export function isReporterEnabled() {
  try {
    const state = { ...store.getState() };
    return state.AppConfigReducer.isReporterEnabled;
  } catch (e) {
    return false;
  }
}

export function getCurrentProject() {
  let localStorageCurProject = null;
  try {
    localStorageCurProject = localStorage.CURRENT_PROJECT_SHORT_NAME;
  } catch (e) {
    localStorageCurProject = null;
  }

  return store.getState().CurrentProjectsReducer.currentProject || localStorageCurProject;
}

export const getAvailableTariffChannels = () => {
  const state = { ...store.getState() };
  const zenflowConfigExcludedChannels =
    Boolean(state.AppConfigReducer.zenflow) && Boolean(state.AppConfigReducer.zenflow.channels)
      ? state.AppConfigReducer.zenflow.channels
      : [];
  let newChannelTypes = {};
  forEach(baseAvailableTariffChannels, (channelTypes, tariffKey) => {
    newChannelTypes[tariffKey] = channelTypes.filter(channelName => {
      return !zenflowConfigExcludedChannels.includes(channelName.toLowerCase());
    });
  });
  return newChannelTypes;
};

/**
 * @param {string} tariff
 * @returns {boolean}
 */
export const isTariffHasVoiceChannels = (tariff = '') => {
  let tariffUniqueName = tariff;
  if (tariff?.indexOf('archived') > 0) {
    tariffUniqueName = tariff.slice(0, tariff.indexOf('archived') - 1);
  }
  const availableTariffChannels = getAvailableTariffChannels();
  return availableTariffChannels[tariffUniqueName || 'yoga']?.find(el => el === 'VOICE_CHANNEL');
};

export const isChannelNotBlocked = channel => {
  const state = { ...store.getState() };
  const zenflowConfigExcludedChannels =
    Boolean(state.AppConfigReducer.zenflow) && Boolean(state.AppConfigReducer.zenflow.channels)
      ? state.AppConfigReducer.zenflow.channels
      : [];
  return zenflowConfigExcludedChannels.includes(channel?.toLowerCase()) === false;
};

export const getAvailableBlocks = blockList => {
  const state = { ...store.getState() };

  let excludedBlocks =
    Boolean(state.AppConfigReducer.zenflow) && Boolean(state.AppConfigReducer.zenflow.unavailableBlocks)
      ? state.AppConfigReducer.zenflow.unavailableBlocks
      : [];
  excludedBlocks = excludedBlocks.map(item => item.toLowerCase());

  return blockList.filter(blockType => {
    return !excludedBlocks.includes(blockType.toLowerCase());
  });
};

const excludeUnavailableChannels = (channelsMabByLang, excludeList) => {
  let availableChannels = {};

  forEach(channelsMabByLang, (channelsMap, langKey) => {
    availableChannels[langKey] = {};

    forEach(channelsMap, (channelValue, channelKey) => {
      if (!excludeList.includes(channelValue.toLowerCase())) {
        availableChannels[langKey][channelKey] = channelValue;
      }
    });
  });

  return availableChannels;
};

export const getChannelTypes = () => {
  const state = { ...store.getState() };
  let zenflowConfigExcludedChannels =
    Boolean(state.AppConfigReducer.zenflow) && Boolean(state.AppConfigReducer.zenflow.channels)
      ? state.AppConfigReducer.zenflow.channels
      : [];
  zenflowConfigExcludedChannels = zenflowConfigExcludedChannels.map(channelName => {
    const item = channelName.toLowerCase();
    switch (item) {
      case 'chatapi':
        return 'chat_api';
      case 'chatwidget':
        return 'chat_widget';
      case 'incoming_webim':
        return 'webim';
      case 'incoming_webim2':
        return 'webim';
      case 'levetex_prompter':
        return 'livetex_prompter';
      default:
        return item;
    }
  });

  return excludeUnavailableChannels(channelTypesByLang, zenflowConfigExcludedChannels);
};

export const getAvailableOperatorsChannels = () => {
  const state = { ...store.getState() };
  let zenflowConfigExcludedOperatorsChannels =
    Boolean(state.AppConfigReducer.zenflow) && Boolean(state.AppConfigReducer.zenflow.livechatChannels)
      ? state.AppConfigReducer.zenflow.livechatChannels
      : [];
  zenflowConfigExcludedOperatorsChannels = zenflowConfigExcludedOperatorsChannels.map(item => item.toLowerCase());

  return excludeUnavailableChannels(operatorChannelsTypes, zenflowConfigExcludedOperatorsChannels);
};

export const getAvailableBusinessChannels = () => {
  const state = { ...store.getState() };
  let zenflowConfigExcludedChannels =
    Boolean(state.AppConfigReducer.zenflow) && Boolean(state.AppConfigReducer.zenflow.channels)
      ? state.AppConfigReducer.zenflow.channels
      : [];
  zenflowConfigExcludedChannels = zenflowConfigExcludedChannels.map(item => item.toLowerCase());

  return excludeUnavailableChannels(businessChannelsTypesByLang, zenflowConfigExcludedChannels);
};

export const showHelp = () => {
  return isConfigEnabled('showHelp');
};

export function isChatWidgetSecurityEnabled() {
  try {
    const state = { ...store.getState() };
    return state.AppConfigReducer.chatWidgetSecurityEnabled;
  } catch (e) {
    return false;
  }
}

export const isCcSessionsAreEnabled = () => {
  return store.getState().AppConfigReducer.ccSessionsAreEnabled;
};

export function getCurrentUserLanguage() {
  try {
    const state = { ...store.getState() };
    return state.CurrentUserReducer.currentUser?.language;
  } catch (e) {
    return false;
  }
}

export function isAccountVerified() {
  try {
    const state = { ...store.getState() };
    return state.CurrentUserReducer.currentUser?.account?.verificationStatus === 'ACCOUNT_VERIFIED' ?? false;
  } catch (e) {
    return false;
  }
}

export function bannersAvailabed() {
  try {
    const state = { ...store.getState() };
    if (Array.isArray(state.AppConfigReducer.zenflow.availableInterviewBanners) === false) return [];
    return state.AppConfigReducer.zenflow.availableInterviewBanners || [];
  } catch (e) {
    return [];
  }
}
