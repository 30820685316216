export const confirmnumberLocalization = {
  'ConfirmNumber enter number': {
    eng: 'Type a number',
    ru: 'Введите номер',
    pt: 'Digite um número',
  },
  'ConfirmNumber title': {
    eng: 'Confirm your phone number',
    ru: 'Подтвердите номер телефона',
  },
  'ConfirmNumber initial message': {
    eng: 'To use $[1] calls, please verify your number.',
    ru: 'Для использования телефонии $[1] подтвердите свой номер телефона.',
    pt: 'Para usar as chamadas $[1], confirme seu número.',
  },
  'ConfirmNumber confirm': {
    eng: 'Continue',
    ru: 'Продолжить',
    pt: 'Confirmar',
  },
  'ConfirmNumber connections problem': {
    eng: 'Connection issue. Please try later',
    ru: 'Проблемы соединения. Пожалуйста, попробуйте позже',
    pt: 'Problema de conexão. Tente mais tarde',
  },
  'ConfirmNumber code is expired': {
    eng: 'Code is expired',
    ru: 'Срок действия кода истек',
    pt: 'O código expirou',
  },
  'ConfirmNumber code is not expired': {
    eng: 'Please retry later',
    ru: 'Повторите звонок позже',
    pt: 'Tente novamente mais tarde',
  },
  'ConfirmNumber caller': {
    eng: 'You will receive a call to number $[1]',
    ru: 'На номер $[1] поступит звонок',
    pt: 'Você receberá uma chamada para o número $[1]',
  },
  'ConfirmNumber last 4 numbers': {
    eng: 'Last 4 digits of inbound call',
    ru: 'Последние 4 цифры входящего номера',
    pt: 'Últimos 4 dígitos da chamada de entrada',
  },
  'ConfirmNumber no call': {
    eng: 'Didn’t get a call?',
    ru: 'Никто не звонил?',
    pt: 'Não recebeu uma chamada?',
  },
  'ConfirmNumber invalid code': {
    eng: 'Invalid confirmation code. $[1] attempt(s) left',
    ru: 'Неправильный код подтверждения. Осталось $[1] попытки',
    pt: 'Código de confirmação inválido. $[1] tentativa(s) restante(s)',
  },
  'ConfirmNumber invalid code without attempts': {
    eng: 'Invalid confirmation code',
    ru: 'Неправильный код подтверждения',
    pt: 'Código de confirmação inválido',
  },
  'ConfirmNumber user is verificated': {
    eng: 'User is already verified',
    ru: 'Пользователь уже верифицирован',
    pt: 'O usuário já foi verificado',
  },
  'ConfirmNumber no more attempts': {
    eng: 'All attempts expired. Please try tomorrow',
    ru: 'Все попытки исчерпаны. Пожалуйста, попробуйте завтра',
    pt: 'Todas as tentativas expiraram. Tente amanhã',
  },
  'ConfirmNumber no more code attempts': {
    eng: 'All attempts expired',
    ru: 'Все попытки исчерпаны',
    pt: 'Todas as tentativas expiraram',
  },
  'ConfirmNumber is not used': {
    eng: 'This phone number is not registered in our database',
    ru: 'Нет пользователя с таким телефоном',
    pt: 'Este número de telefone não está registrado em nosso banco de dados',
  },
  'ConfirmNumber retry call': {
    eng: 'Retry call',
    ru: 'Повторить звонок',
    pt: 'Repetir chamada',
  },
  'ConfirmNumber already used in another account': {
    eng: 'This number is registered with another $[1] account. Please use another number for verification',
    ru: 'Указанный номер уже используется в другом аккаунте $[1]. Используйте другой номер телефона для верификации',
    pt: 'Este número está registrado em outra conta $[1]. Use outro número para confirmação',
  },
  'ConfirmNumber already used': {
    eng: 'The number is already in use',
    ru: 'Номер уже занят',
    pt: 'O número já está em uso',
  },
  'ConfirmNumber input tooltip': {
    eng: 'Once the number is verified, you can change it by contacting us at {contactUsEmail}.',
    ru: 'После подтверждения номер можно изменить, обратившись в поддержку: {contactUsEmail}.',
    pt: 'Depois que o número for verificado, você poderá alterá-lo entrando em contato conosco em {contactUsEmail}.',
  },
  'ConfirmNumber info attempt': {
    eng: 'Enter $[1] of the inbound call number',
    ru: 'Введите $[1] входящего номера',
    pt: 'Insira $[1] do número da chamada recebida',
  },
  'ConfirmNumber info count attempt': {
    eng: 'the last 4 digits',
    ru: 'последние 4 цифры',
    pt: 'os últimos 4 dígitos',
  },
  'ConfirmNumber in editor': {
    eng: 'Please confirm your phone number to be able to send SMS messages',
    ru: 'Для отправки SMS-сообщений подтвердите свой номер телефона',
    pt: 'Confirme seu número de telefone para poder enviar mensagens SMS',
  },
  'ConfirmNumber no more attempts support': {
    eng: 'All attempts expired. Please contact our customer support team.',
    ru: 'Все попытки исчерпаны. Обратитесь в техническую поддержку.',
    pt: 'Todas as tentativas expiraram. Entre em contato com nossa equipe de suporte ao cliente.',
  },
  'ConfirmNumber:ResentText': {
    eng: 'Resend in $[1] seconds',
    ru: 'Отправить снова через $[1] секунд',
    pt: 'Reenviar em $[1] segundos',
    cn: 'Resend in $[1] seconds',
  },
  'ConfirmNumber:SmsCode:HelperText': {
    eng: 'You can request the code again after $[1] seconds.',
    ru: 'Запросить код повторно можно через $[1] сек.',
  },
  'ConfirmNumber:SmsCode:SendText': {
    eng: 'The code was sent to $[1].',
    ru: 'Код отправлен на номер $[1].',
  },
  'ConfirmNumber:SmsCode:ChangeNumber': {
    eng: 'Change number',
    ru: 'Изменить номер',
  },
  'ConfirmNumber:SmsCode:ContactUs': {
    eng: 'If you did not receive the message, please contact our team via support chat or email ',
    ru: 'Если вы не получили сообщение, напишите нам через чат поддержки или на почту ',
  },
  'ConfirmNumber:Attempts:1': {
    eng: '$[1] attempt',
    ru: '$[1] попытка',
    pt: '$[1] tentativa',
    cn: '$[1] attempt',
  },
  'ConfirmNumber:Attempts:2': {
    eng: '$[1] attempts',
    ru: '$[1] попытки',
    pt: '$[1] tentativas',
    cn: '$[1] attempts',
  },
  'ConfirmNumber:Attempts:5': {
    eng: '$[1] attempts',
    ru: '$[1] попыток',
    pt: '$[1] tentativas',
    cn: '$[1] attempts',
  },
  'ConfirmNumber:BeError:accountsadmin.captcha.missing.header': {
    eng: 'CAPTCHA validation failed',
    ru: 'Ошибка при проверке капчи',
    pt: 'Falha na validação do CAPTCHA',
    cn: 'CAPTCHA validation failed',
  },
  'ConfirmNumber:BeError:common.error.internal': {
    eng: 'Internal error: $[1]. Please contact our customer support team with the error code number.',
    ru: 'Внутренняя ошибка $[1]. Обратитесь в техническую поддержку и сообщите код ошибки.',
    pt: 'Erro interno: $[1]. Entre em contato com nossa equipe de suporte ao cliente com o número do código de erro.',
    cn: 'An internal error has occurred. Please contact our customer support team with the following code: $[1]',
  },
  'ConfirmNumber:BeError:accountsadmin.smscode.attempts.limit': {
    eng: 'All attempts expired. Please contact our customer support team.',
    ru: 'Все попытки исчерпаны. Обратитесь в техническую поддержку.',
    pt: 'Todas as tentativas expiraram. Entre em contato com nossa equipe de suporte ao cliente.',
  },
  'ConfirmNumber:BeError:accountsadmin.smscode.expired': {
    eng: 'The SMS message code has expired. Request another',
    ru: 'Срок действия SMS-кода истек. Запросите повторную отправку',
    pt: 'O código da mensagem SMS expirou. Solicitar outro',
    cn: 'The SMS message code has expired. Request another',
  },
  'ConfirmNumber:BeError:accountsadmin.smscode.send.again': {
    eng: 'Incorrect code. Request another',
    ru: 'Неверный код. Запросите повторную отправку кода',
    pt: 'Código incorreto. Solicitar outro',
    cn: 'Incorrect code. Request another',
  },
  'ConfirmNumber:BeError:accountsadmin.smscode.too.many.attempts': {
    eng: 'Too many attempts.',
    ru: 'Превышен лимит повторной отправки.',
    pt: 'Muitas tentativas.',
    cn: 'Too many attempts.',
  },
  'ConfirmNumber:BeError:accountsadmin.validation.constraints.Phone': {
    eng: 'Incorrect phone number format',
    ru: 'Некорректный формат номера телефона',
    pt: 'Formato incorreto de número de telefone',
    cn: 'Incorrect phone number format',
  },
  'ConfirmNumber:BeError:accountsadmin.smscode.phone.already.used': {
    eng: 'This phone number is already taken',
    ru: 'Номер телефона уже занят другим пользователем',
    pt: 'Este número de telefone já está ocupado',
    cn: 'This phone number is already taken',
  },
  'ConfirmNumber:BeError:accountsadmin.smscode.enter.again': {
    eng: 'Incorrect code. $[1] left',
    ru: 'Неверный код. Осталось $[1]',
    pt: 'Código incorreto. $[1] restantes',
    cn: 'Incorrect code. $[1] left',
  },
  'ConfirmNumber:BeError:accountsadmin.captcha.validation.failed': {
    eng: 'CAPTCHA validation failed',
    ru: 'Ошибка при проверке капчи',
    pt: 'Falha na validação CAPTCHA',
    cn: 'CAPTCHA validation failed',
  },
  'ConfirmNumber:ResendSms': {
    eng: 'Send again',
    ru: 'Отправить SMS еще раз',
    pt: 'Enviar novamente',
    cn: 'Send again',
  },
};
