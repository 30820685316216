import * as BotprojectsActions from '../../constants/botprojects.actions';
import { parseErrors } from '../../pipes/pureFunctions';

const InitialState = {
  botList: [],
  errors: [],
  fetching: false,
  fetchingProjects: false,
  fetched: true,
};

export default function BotProjectsReducer(state = InitialState, action) {
  switch (action.type) {
    case BotprojectsActions.SAVE_EDITABLE_PROJECT_FULFILLED: {
      let newBotList = [...state.botList];
      newBotList.splice(
        newBotList.findIndex(item => item.id === action.payload.data.id),
        1,
        action.payload.data
      );
      return {
        ...state,
        botList: newBotList,
        fetching: false,
        fetched: true,
      };
    }
    case BotprojectsActions.CLONE_PROJECT_FULFILLED:
    case BotprojectsActions.CREATE_PROJECT_FULFILLED: {
      let newBotList = [...state.botList];
      newBotList.push(action.payload.data);
      return {
        ...state,
        botList: newBotList,
        fetching: false,
        fetched: true,
      };
    }
    case BotprojectsActions.UPDATE_CHANNELS: {
      if (action?.data?.shortName) {
        let botShortNameWithIncoming = null;
        let botList = state.botList.map(bot => {
          if (bot.shortName === action.data.shortName) {
            bot.botConfigs = [...action.data.botConfigs].filter(({ channelType, type, resteriskAcceptIncoming }) => {
              if (channelType === 'RESTERISK' && resteriskAcceptIncoming) {
                botShortNameWithIncoming = bot.shortName;
              }
              return Boolean(channelType || type);
            });
          }
          return { ...bot };
        });

        botList = botList.map(bot => {
          bot.resteriskAcceptIncoming = botShortNameWithIncoming === bot.shortName;
          return bot;
        });

        return {
          ...state,
          botList,
        };
      } else {
        return state;
      }
    }
    case BotprojectsActions.REMOVE_EDITABLE_PROJECT_FULFILLED: {
      let newBotList = [...state.botList];
      let bot = newBotList.find(item => item.id === action.payload.data.id);
      if (action.payload.config.withoutPending) {
        newBotList = newBotList.filter(item => item.id !== action.payload.data.id);
      } else {
        bot.pending = false;
      }
      return {
        ...state,
        botList: newBotList,
        fetching: false,
        fetched: true,
      };
    }
    case BotprojectsActions.FETCH_PROJECTS_FULFILLED: {
      const botList = action.payload.data;
      botList.forEach(bot => {
        // ZFL-9084 | In older projects botType can be null
        if (bot.botType === null) bot.botType = 'CHAT_BOT';
        if (bot.templateJson) {
          try {
            bot.parsedJson = JSON.parse(bot.templateJson);
            if (bot.parsedJson.json) {
              bot.parsedJson = { ...bot.parsedJson, ...JSON.parse(bot.parsedJson.json) };
            }
          } catch (e) {
            console.error(e);
          }
        }
      });
      return {
        ...state,
        botList,
        fetchingProjects: false,
      };
    }
    case BotprojectsActions.FETCH_PROJECTS_UNIQ_CLIENTS_FULFILLED: {
      const projectUniqClientsDto = action.payload.data;
      const newBotList = state.botList.map(bot => {
        const foundStats = projectUniqClientsDto.find(uniqClients => uniqClients.projectShortName === bot.shortName);
        if (foundStats) {
          return { ...bot, uniqueClients: foundStats.totalCount };
        }
        return bot;
      });
      return {
        ...state,
        botList: newBotList,
        fetchingProjects: false,
      };
    }
    case BotprojectsActions.FETCH_PROJECTS_PENDING: {
      return {
        ...state,
        fetchingProjects: true,
      };
    }
    case BotprojectsActions.FETCH_PROJECTS_REJECTED: {
      return {
        ...state,
        fetchingProjects: false,
      };
    }
    case BotprojectsActions.SET_BOT_TO_DELETE: {
      let botItem = state.botList.find(item => item.id === action.id);
      botItem.pending = true;
      botItem.deleting = true;
      const newBotList = [...state.botList];
      return {
        ...state,
        botList: newBotList,
      };
    }
    case BotprojectsActions.FINAL_DELETE: {
      let deletingBotIndex = state.botList.findIndex(item => item.id === action.id);
      const newBotList = [...state.botList];
      newBotList.splice(deletingBotIndex, 1);
      return {
        ...state,
        botList: newBotList,
      };
    }
    case BotprojectsActions.REMOVE_EDITABLE_PROJECT_PENDING:
    case BotprojectsActions.SAVE_EDITABLE_PROJECT_PENDING:
    case BotprojectsActions.CLONE_PROJECT_PENDING:
    case BotprojectsActions.CREATE_PROJECT_PENDING: {
      return {
        ...state,
        fetching: true,
        fetched: false,
      };
    }
    case BotprojectsActions.REMOVE_EDITABLE_PROJECT_REJECTED:
    case BotprojectsActions.SAVE_EDITABLE_PROJECT_REJECTED:
    case BotprojectsActions.CLONE_PROJECT_REJECTED:
    case BotprojectsActions.CREATE_PROJECT_REJECTED: {
      const errors = parseErrors(action.payload.response);

      return {
        ...state,
        errors: errors,
        fetching: false,
        fetched: true,
      };
    }
    case BotprojectsActions.UPDATE_PROJECT_TAGS: {
      const newBotList = [...state.botList];
      const foundBotIndex = newBotList.findIndex(botItem => botItem.shortName === action.payload.projectShortName);
      if (foundBotIndex > -1) {
        let newBot = {
          ...newBotList[foundBotIndex],
          tagLabels: action.payload.tagsList,
        };
        newBotList.splice(foundBotIndex, 1, newBot);
      }
      return {
        ...state,
        botList: newBotList,
      };
    }
    case BotprojectsActions.DELETE_ACCOUNT_TAG: {
      const newBotList = [...state.botList].reduce((currentBotList, currentBotItem) => {
        const newBotItem = { ...currentBotItem };
        newBotItem.tagLabels = newBotItem.tagLabels.filter(tagLabel => tagLabel.id !== action.payload.tagId);
        currentBotList.push(newBotItem);
        return currentBotList;
      }, []);

      return {
        ...state,
        botList: newBotList,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
