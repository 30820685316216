import React, { useCallback } from 'react';
import { Button, InputText, Spinner } from '@just-ai/just-ui';

import { t } from 'localization';
import history from 'appHistory';

import { useCreateProjectSkill } from 'modules/TemplatesWizard/hooks/useProjectSkill';

import classes from '../../graph-way/graph-way-end/graphWayEndContainer.module.scss';
import { BASE_WIZARD_PATH } from '../../../shared/consts';
import { useProjectNameHook } from '../../../shared/nameHook';

export const SkillWay = () => {
  const [createProjectState, createProjectApi] = useCreateProjectSkill();

  const { name, trimmedName, nameError, triggerValidation, setNameHandler } = useProjectNameHook();

  const submit = useCallback(async () => {
    createProjectState.reset();
    triggerValidation();
    if (trimmedName) {
      const newProject = await createProjectApi(trimmedName);
      if (!newProject) return;
      history.push(`${BASE_WIZARD_PATH}/skill/skills-select/${newProject.shortName}`);
    }
  }, [createProjectApi, createProjectState, triggerValidation, trimmedName]);

  return (
    <div className={classes.graphWayEndContainer}>
      <h1 className='mt-3 mb-6'>{t('CreateProject:SkillWayStart:Title')}</h1>
      <div className={classes.finalCard}>
        {createProjectState.loading && <Spinner />}
        <InputText
          autoFocus
          errorText={nameError || createProjectState.error || ''}
          fullWidth
          placeholder={t('CreateProject:GraphWayEnd:ProjectName:InputPlaceholder')}
          data-test-id='CreateProject:SkillWay:ProjectName'
          value={name}
          onChange={setNameHandler}
          maxLength={30}
        />

        <Button
          className='d-block align-self-start mt-4'
          color='primary'
          disabled={createProjectState.loading}
          onClick={submit}
          data-test-id='CreateProject:SkillWay:ButtonNext'
        >
          {t('CreateProject:SkillWayStart:ButtonText')}
        </Button>
      </div>
    </div>
  );
};
