var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, } from "react";
import { IconButton } from "..";
import { Collapse as ReactstrapCollapse, } from "reactstrap";
import "./style.scss";
import classNames from "classnames";
export function Collapse(_a) {
    var { label, className, headClassName, bodyClassName, defaultOpened = false, dark, collapseButton, children, ["data-test-id"]: dataTestId } = _a, restProps = __rest(_a, ["label", "className", "headClassName", "bodyClassName", "defaultOpened", "dark", "collapseButton", "children", "data-test-id"]);
    const [isOpened, setOpened] = useState(defaultOpened);
    const open = () => setOpened(true);
    const close = () => setOpened(false);
    return (_jsxs("div", { className: classNames("justui_collapse__container", isOpened && "justui_collapse__container-opened", className), "data-test-id": dataTestId, children: [_jsxs("div", { className: classNames("justui_collapse__header", isOpened && "justui_collapse__header-opened", headClassName), children: [typeof label === "function" ? label : _jsx("span", { children: label }), collapseButton ? (collapseButton) : (_jsx(IconButton, { onClick: isOpened ? close : open, name: "faChevronDown", withoutPadding: true, square: true, color: "primary", flat: true, className: classNames("justui_collapse__header_collapseButton", isOpened && "justui_collapse__header_collapseButton-opened") }))] }), _jsx(ReactstrapCollapse, Object.assign({ isOpen: isOpened, className: classNames("justui_collapse__body", bodyClassName) }, restProps, { children: children }))] }));
}
