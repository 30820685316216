import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import localize from '../../localization';
import {
  withStyles,
  ActionConfirmModal,
  SvgIcon,
  Grid,
  AtreidesTypo,
  Button,
  AtreidesIcon,
  Spinner,
  AtreidesTooltip,
} from 'altreidsds';
import history from '../../appHistory';
import classNames from 'classnames';

class BotWillCallModal extends Component {
  state = {
    open: false,
    fetching: false,
  };

  toggle = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  call = () => {
    this.setState({
      fetching: true,
    });
    setTimeout(() => {
      this.setState({
        fetching: false,
      });
    }, 2000);
  };

  goToTariff = () => {
    this.setState({
      open: false,
    });
    history.push('personal/?tab=0');
  };

  render() {
    const { classes, currentUser } = this.props;
    const { open, fetching } = this.state;

    const currentSeconds =
      Boolean(currentUser) && Boolean(currentUser.tariff) && Boolean(currentUser.tariff.optionsData)
        ? currentUser.tariff.optionsData.maxSeconds - currentUser.tariff.optionsData.currentSeconds
        : 0;

    return (
      <ActionConfirmModal
        disableBackdropClick={true}
        open={open}
        onClose={this.toggle}
        title={localize.translate('BotWillCallModal title')}
        onCancelClick={this.toggle}
        cancelText={localize.translate('Cancel')}
        onOkClick={this.call}
        okText={localize.translate('BotWillCallModal confirm')}
        okDisabled={fetching}
      >
        <Grid container spacing={0}>
          {fetching ? <Spinner /> : null}
          <Grid item className={classes.botMorda}>
            <SvgIcon viewBox='0 0 196 192' className={classes.svgMorda}>
              <rect width='196' height='192' fill='#1a5e8f' opacity='0' />
              <path d='M133,133v25.9a71.86,71.86,0,0,1-70,0V133Z' fill='#c8e6fc' />
              <path
                d='M131,135v22.72a69.9,69.9,0,0,1-66,0V135h66m2-2H63v25.9a71.86,71.86,0,0,0,70,0V133Z'
                fill='#1a5e8f'
              />
              <path
                d='M131.08,134.92v22.84a69.92,69.92,0,0,1-66.16,0V134.92h66.16M133,133H63v25.9a71.86,71.86,0,0,0,70,0V133Z'
                fill='#1a5e8f'
              />
              <path d='M23.08,93a75,75,0,0,1,149.84,0h2A77,77,0,0,0,21.08,93Z' fill='#1a5e8f' />
              <path d='M172.81,101a75,75,0,0,1-149.62,0h-2a77,77,0,0,0,153.64,0Z' fill='#1a5e8f' />
              <rect x='39' y='62' width='118' height='72' rx='18' fill='#c8e6fc' />
              <path
                d='M141.07,64A14,14,0,0,1,155,77.93v40.14A14,14,0,0,1,141.07,132H54.93A14,14,0,0,1,41,118.07V77.93A14,14,0,0,1,54.93,64h86.14m0-2H54.93A15.93,15.93,0,0,0,39,77.93v40.14A15.93,15.93,0,0,0,54.93,134h86.14A15.93,15.93,0,0,0,157,118.07V77.93A15.93,15.93,0,0,0,141.07,62Z'
                fill='#1a5e8f'
              />
              <polygon points='100 53 96 53 96 39 100 39 100 53 100 53' fill='#1a5e8f' />
              <path d='M105,33a7,7,0,1,0-7,7A7,7,0,0,0,105,33Z' fill='#1a5e8f' />
              <path d='M98,26v1a6,6,0,1,1-6,6,6,6,0,0,1,6-6V26m0,0h0a7,7,0,0,0,0,14h0a7,7,0,0,0,0-14Z' fill='#1a5e8f' />
              <path
                d='M86,64h24V61.24c0-4.18-4.67-7.85-10.93-8.21C92,52.62,86,56.34,86,61Z'
                fill='#c8e6fc'
                fillRule='evenodd'
              />
              <path
                d='M98,54l1,0c5.07.32,9,3.38,9,7v1H88V60.73C88,57.08,92.58,54,98,54m0-2c-6.63,0-12,3.91-12,8.73V64h24V61c0-4.56-4.67-8.56-10.93-9L98,52Z'
                fill='#1a5e8f'
              />
              <path d='M103,33a5,5,0,1,0-5,5,5,5,0,0,0,5-5Z' fill='#c8e6fc' fillRule='evenodd' />
              <polygon points='30 95 30 99 16 99 16 95 30 95 30 95' fill='#1a5e8f' />
              <path d='M10,90a7,7,0,1,0,7,7A7,7,0,0,0,10,90Z' fill='#1a5e8f' />
              <path
                d='M10,90v1a6,6,0,0,1,4.25,10.23A5.93,5.93,0,0,1,10,103,6,6,0,0,1,5.75,92.77,5.92,5.92,0,0,1,10,91V90m0,0h0a7,7,0,0,0,0,14h0a7,7,0,0,0,0-14Z'
                fill='#1a5e8f'
              />
              <path
                d='M41,109V85H38.24c-4.18,0-7.85,4.67-8.21,10.93C29.62,103,33.34,109,38,109Z'
                fill='#c8e6fc'
                fillRule='evenodd'
              />
              <path
                d='M39,87v20H37.73a5.43,5.43,0,0,1-3.89-1.88A12.3,12.3,0,0,1,31,96.05c.32-5.07,3.38-9,7-9h1m2-2H38c-4.56,0-8.56,4.67-9,10.93C28.59,103,32.65,109,37.73,109H41V85Z'
                fill='#1a5e8f'
              />
              <path d='M10,92a5,5,0,1,0,5,5,5,5,0,0,0-5-5Z' fill='#c8e6fc' fillRule='evenodd' />
              <polygon points='166 95 166 99 180 99 180 95 166 95 166 95' fill='#1a5e8f' />
              <path d='M186,90a7,7,0,1,1-7,7A7,7,0,0,1,186,90Z' fill='#1a5e8f' />
              <path
                d='M186,90v1a6,6,0,0,1,6,6,6,6,0,0,1-6,6,6,6,0,0,1-6-6,6,6,0,0,1,6-6V90m0,0a7,7,0,0,0,0,14h0a7,7,0,0,0,0-14Z'
                fill='#1a5e8f'
              />
              <path
                d='M155,109V85h2.76c4.18,0,7.85,4.67,8.21,10.93.41,7.11-3.31,13.07-8,13.07Z'
                fill='#c8e6fc'
                fillRule='evenodd'
              />
              <path
                d='M158,87c3.58,0,6.64,4,7,9a12.3,12.3,0,0,1-2.81,9.07,5.43,5.43,0,0,1-3.89,1.88H157V87h1m0-2h-3v24h3.27c5.08,0,9.14-6,8.7-13.07-.4-6.26-4.4-10.93-9-10.93Z'
                fill='#1a5e8f'
              />
              <path d='M186,92a5,5,0,1,1-5,5,5,5,0,0,1,5-5Z' fill='#c8e6fc' fillRule='evenodd' />
              <path
                d='M126.58,104h-6.16l-.27-.57a13.84,13.84,0,0,1-1.19-4,14.17,14.17,0,0,1-3.65,2l-.59.21-4.36-4.36.21-.59a14.17,14.17,0,0,1,2-3.65,14,14,0,0,1-4-1.19l-.57-.27V85.42l.57-.27a14,14,0,0,1,4-1.19c-.13-.15-.25-.32-.38-.5a14.3,14.3,0,0,1-1.59-3.15l-.21-.59,4.36-4.36.59.21a14.17,14.17,0,0,1,3.65,2,13.84,13.84,0,0,1,1.19-4l.27-.57h6.16l.27.57a14.09,14.09,0,0,1,1.19,4,14.17,14.17,0,0,1,3.65-2l.59-.21,4.36,4.36-.21.59a14.17,14.17,0,0,1-2,3.65,14.09,14.09,0,0,1,4,1.19l.57.27v6.16l-.57.27a14,14,0,0,1-4,1.19,14.17,14.17,0,0,1,2,3.65l.21.59-4.36,4.36-.59-.21a14.17,14.17,0,0,1-3.65-2,14.09,14.09,0,0,1-1.19,4Zm-4.88-2h3.6a7.48,7.48,0,0,0,.73-3.45l-.27-.86,2.63-1.09.43.8a7.38,7.38,0,0,0,3,1.92l2.55-2.55a7.38,7.38,0,0,0-1.92-3l-.8-.43,1.09-2.62.86.26A7.48,7.48,0,0,0,137,90.3V86.7a7.48,7.48,0,0,0-3.45-.73l-.86.26-1.09-2.62.8-.43a7.38,7.38,0,0,0,1.92-3l-2.55-2.55a7.38,7.38,0,0,0-3,1.92l-.43.8-2.63-1.09.27-.86A7.48,7.48,0,0,0,125.3,75h-3.6a7.48,7.48,0,0,0-.73,3.45l.26.86-2.62,1.09-.43-.8a7.38,7.38,0,0,0-3-1.92l-2.55,2.55a11.47,11.47,0,0,0,1.13,2.09,2.77,2.77,0,0,0,.79.86l.8.43-1.09,2.62-.86-.26a7.5,7.5,0,0,0-3.45.73v3.6a7.48,7.48,0,0,0,3.45.73l.86-.26,1.09,2.62-.8.43a7.38,7.38,0,0,0-1.92,3l2.55,2.55a7.38,7.38,0,0,0,3-1.92l.43-.8,2.62,1.09-.26.86A7.48,7.48,0,0,0,121.7,102Zm2.24-6.3a6.76,6.76,0,1,1,4.78-11.54h0a6.76,6.76,0,0,1-4.78,11.54Zm0-11.52a4.76,4.76,0,1,0,3.37,1.39h0A4.75,4.75,0,0,0,123.94,84.18Z'
                fill='#1a5e8f'
              />
              <path
                d='M75.58,104H69.42l-.27-.57a13.84,13.84,0,0,1-1.19-4,14.17,14.17,0,0,1-3.65,2l-.59.21-4.36-4.36.21-.59a14.17,14.17,0,0,1,2-3.65,14,14,0,0,1-4-1.19L57,91.58V85.42l.57-.27a14.09,14.09,0,0,1,4-1.19,14.17,14.17,0,0,1-2-3.65l-.21-.59,4.36-4.36.59.21a14.17,14.17,0,0,1,3.65,2,13.84,13.84,0,0,1,1.19-4l.27-.57h6.16l.27.57a14.09,14.09,0,0,1,1.19,4,14.17,14.17,0,0,1,3.65-2l.59-.21,4.36,4.36-.21.59a14.17,14.17,0,0,1-2,3.65,14.09,14.09,0,0,1,4,1.19l.57.27v6.16l-.57.27a14,14,0,0,1-4,1.19,14.17,14.17,0,0,1,2,3.65l.21.59-4.36,4.36-.59-.21a14.17,14.17,0,0,1-3.65-2,14.09,14.09,0,0,1-1.19,4Zm-4.88-2h3.6A7.48,7.48,0,0,0,75,98.55l-.27-.86,2.63-1.09.43.8a7.38,7.38,0,0,0,3,1.92l2.55-2.55a7.38,7.38,0,0,0-1.92-3l-.8-.43,1.09-2.62.86.26A7.5,7.5,0,0,0,86,90.3V86.7A7.48,7.48,0,0,0,82.55,86l-.86.26L80.6,83.61l.8-.43a7.38,7.38,0,0,0,1.92-3l-2.55-2.55a7.38,7.38,0,0,0-3,1.92l-.43.8-2.63-1.09.27-.86A7.48,7.48,0,0,0,74.3,75H70.7A7.48,7.48,0,0,0,70,78.45l.26.86L67.61,80.4l-.43-.8a7.38,7.38,0,0,0-3-1.92l-2.55,2.55a7.38,7.38,0,0,0,1.92,3l.8.43-1.09,2.62L62.45,86A7.5,7.5,0,0,0,59,86.7v3.6a7.48,7.48,0,0,0,3.45.73l.86-.26,1.09,2.62-.8.43a7.38,7.38,0,0,0-1.92,3l2.55,2.55a7.38,7.38,0,0,0,3-1.92l.43-.8,2.62,1.09-.26.86A7.48,7.48,0,0,0,70.7,102Zm1.36-6.3a6.76,6.76,0,1,1,4.78-2A6.72,6.72,0,0,1,72.06,95.7Zm0-11.52a4.76,4.76,0,1,0,3.37,1.39A4.75,4.75,0,0,0,72.06,84.18Z'
                fill='#1a5e8f'
              />
              <rect x='65' y='134' width='66' height='6' fill='#9dc3e0' style={{ isolation: 'isolate' }} />
              <path
                d='M98,121.65c-11.52,0-22.8-4-27.44-9.74a1,1,0,0,1,1.56-1.26c4.28,5.3,14.92,9,25.88,9s21.6-3.7,25.88-9a1,1,0,1,1,1.56,1.26C120.8,117.64,109.52,121.65,98,121.65Z'
                fill='#1a5e8f'
              />
            </SvgIcon>
          </Grid>
          <Grid item className={classes.flexGrow}>
            <AtreidesTypo className={classes.subtitle} type='h1'>
              {localize.translate('BotWillCallModal subtitle', '+7 921 555 55 55')}{' '}
              <AtreidesTooltip placement='top' title={localize.translate('BotWillCallModal tooltip subtitle content')}>
                <AtreidesIcon name='icon_iv2' color='primary' className={classes.icon} />
              </AtreidesTooltip>
            </AtreidesTypo>
            <div className={classNames(classes.customBox, classes.customBoxGreen)}>
              <Grid container spacing={0} justify='space-between' alignItems='center'>
                <Grid item>
                  <div className={classes.maxHeight}>
                    <AtreidesTypo className={classes.label} variant='regular' noMargin>
                      {localize.translate('BotWillCallModal current balance')}
                      <AtreidesTooltip
                        placement='top'
                        title={localize.translate('BotWillCallModal tooltip balance content', {
                          contactUsEmail: this.props.contactUsEmail,
                        })}
                      >
                        <AtreidesIcon name='icon_iv2' color='primary' className={classes.icon} />
                      </AtreidesTooltip>
                    </AtreidesTypo>
                  </div>
                  <div>
                    <AtreidesTypo type='b' noMargin>
                      {Math.floor(currentSeconds / 60)} {localize.translate('min')}{' '}
                      <sup className={classes.sup}>
                        {Math.floor(currentSeconds % 60) === 0 ? '00' : Math.floor(currentSeconds % 60)}{' '}
                        {localize.translate('sec')}
                      </sup>
                    </AtreidesTypo>
                  </div>
                </Grid>
                <Grid item>
                  <Button size='small' onClick={this.goToTariff} variant='contained' color='success'>
                    {localize.translate('BotWillCallModal buy minutes')}
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </ActionConfirmModal>
    );
  }
}

BotWillCallModal.propTypes = {
  contactUsEmail: PropTypes.string,
};

const styles = theme => ({
  icon: {
    cursor: 'pointer',
  },
  maxHeight: {
    maxHeight: 16,
  },
  label: {
    '&.p': {
      fontSize: '0.75rem',
      lineHeight: '1rem',
    },
  },
  sup: {
    fontSize: '10px',
  },
  customBox: {
    border: `1px solid var(--gray-200)`,
    borderRadius: '3px',
    padding: '8px 16px',
    backgroundColor: 'var(--white)',
  },
  customBoxGreen: {
    borderColor: 'var(--green-200)',
    backgroundColor: `var(--green-100)`,
    color: 'var(--success)',
  },
  subtitle: {
    whiteSpace: 'pre-line',
    '&.h1': {
      color: 'var(--primary)',
    },
  },
  svgMorda: {
    width: 192,
    height: 192,
    fontSize: 192,
  },
  botMorda: {
    minWidth: 192,
  },
  flexGrow: {
    paddingLeft: '24px',
    flexGrow: '1',
  },
});

function mapStateToProps(state) {
  return {
    contactUsEmail: state.AppConfigReducer.cloudDomains?.aimylogic?.email?.support ?? '',
  };
}

export default connect(mapStateToProps, undefined)(withStyles(styles, { withTheme: true })(BotWillCallModal));
