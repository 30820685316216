import { GPTAIApi } from '@just-ai/api/dist/generated/Editorbe';
import { AuthorizationEndpointApi } from 'api/client/accountadmin';

import {
  LOGIN,
  CLEAN_LOGIN_ERRORS,
  LOGOUT,
  SET_CURRENT_USER,
  MULTIPLE,
  CHECK_TOKEN,
  DROP_CURRENT_USER,
  DROP_EDITABLE_USER,
  SEND_FEEDBACK,
  SET_LANGUAGE,
  GET_USER_ACCOUNT,
  GET_USER_SUBSCRIPTION,
  CHANGE_USER_SUBSCRIPTION_STATUS,
  GET_USER_ACCOUNT_PAYMENT_DATA,
  BLOCK_CURRENT_USER_TARIFF,
  GET_AVAILABLE_MINUTES_PACKAGES,
  GET_VERIFICATION_CODE,
  CHECK_VERIFICATION_CODE,
  CONTRACT_REQUEST,
  UPLOAD_FILE_TO_SERVER,
  GET_CALLS_AVAILABILITY,
  CHECK_USER_PHONE_COUNTRY_CODE,
  GET_AVAILABLE_SMS_PACKAGES,
  BUY_ADDITIONAL_PACKAGES,
  CHANGE_EMAIL_BY_TOKEN,
  SET_PACKAGES_COUNT,
  GET_AVAILABLE_UNIQUES_PACKAGES,
  GET_MANUAL_CONTROL_INFO,
  SET_CURRENT_USER_PHONE,
  SET_CURRENT_USER_ON_VERIFICATION,
  GET_GPT_STATUS,
} from '../../constants/currentUser.actions';
import { axios } from '../../pipes/functions';
import AccountsadminService from '@just-ai/api/dist/services/AccountsadminService';

const Base64 = {
  // private property
  _keyStr: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=',

  // public method for encoding
  encode: function (input) {
    let output = '';
    let chr1, chr2, chr3, enc1, enc2, enc3, enc4;
    let i = 0;

    input = Base64._utf8_encode(input);

    while (i < input.length) {
      chr1 = input.charCodeAt(i++);
      chr2 = input.charCodeAt(i++);
      chr3 = input.charCodeAt(i++);

      enc1 = chr1 >> 2;
      enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
      enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
      enc4 = chr3 & 63;

      if (isNaN(chr2)) {
        enc3 = enc4 = 64;
      } else if (isNaN(chr3)) {
        enc4 = 64;
      }

      output =
        output +
        this._keyStr.charAt(enc1) +
        this._keyStr.charAt(enc2) +
        this._keyStr.charAt(enc3) +
        this._keyStr.charAt(enc4);
    } // Whend

    return output;
  }, // End Function encode

  // public method for decoding
  decode: function (input) {
    let output = '';
    let chr1, chr2, chr3;
    let enc1, enc2, enc3, enc4;
    let i = 0;

    input = input.replace(/[^A-Za-z0-9\+\/]/g, '');
    while (i < input.length) {
      enc1 = this._keyStr.indexOf(input.charAt(i++));
      enc2 = this._keyStr.indexOf(input.charAt(i++));
      enc3 = this._keyStr.indexOf(input.charAt(i++));
      enc4 = this._keyStr.indexOf(input.charAt(i++));

      chr1 = (enc1 << 2) | (enc2 >> 4);
      chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
      chr3 = ((enc3 & 3) << 6) | enc4;

      output += String.fromCharCode(chr1);

      if (enc3 !== 64) {
        output += String.fromCharCode(chr2);
      }

      if (enc4 !== 64) {
        output += String.fromCharCode(chr3);
      }
    } // Whend

    output = Base64._utf8_decode(output);

    return output;
  }, // End Function decode

  // private method for UTF-8 encoding
  _utf8_encode: function (string) {
    let utftext = '';
    string = string.replace(/\r\n/g, '\n');

    for (let n = 0; n < string.length; n++) {
      const c = string.charCodeAt(n);

      if (c < 128) {
        utftext += String.fromCharCode(c);
      } else if (c > 127 && c < 2048) {
        utftext += String.fromCharCode((c >> 6) | 192);
        utftext += String.fromCharCode((c & 63) | 128);
      } else {
        utftext += String.fromCharCode((c >> 12) | 224);
        utftext += String.fromCharCode(((c >> 6) & 63) | 128);
        utftext += String.fromCharCode((c & 63) | 128);
      }
    } // Next n

    return utftext;
  }, // End Function _utf8_encode

  // private method for UTF-8 decoding
  _utf8_decode: function (utftext) {
    let string = '';
    let i = 0;
    let c = 0,
      c2 = 0,
      c3 = 0;

    while (i < utftext.length) {
      c = utftext.charCodeAt(i);

      if (c < 128) {
        string += String.fromCharCode(c);
        i++;
      } else if (c > 191 && c < 224) {
        c2 = utftext.charCodeAt(i + 1);
        string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
        i += 2;
      } else {
        c2 = utftext.charCodeAt(i + 1);
        c3 = utftext.charCodeAt(i + 2);
        string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
        i += 3;
      }
    } // Whend

    return string;
  }, // End Function _utf8_decode
};

export const login = (obj = null, autoLoginToken = null) => {
  if (Boolean(obj)) {
    return {
      type: LOGIN,
      payload: axios.get('/restapi/public/authenticate', {
        headers: {
          Authorization: 'Basic ' + Base64.encode(obj.email + ':' + obj.password),
          'X-Requested-With': 'XMLHttpRequest',
        },
      }),
    };
  }
  return {
    type: LOGIN,
    payload: axios.get('/restapi/public/authenticate', {
      params: { autoLoginToken },
    }),
  };
};

export const cleanLoginErrors = () => {
  return {
    type: CLEAN_LOGIN_ERRORS,
  };
};

export const logout = () => {
  const service = new AuthorizationEndpointApi({}, '', axios);
  return {
    type: LOGOUT,
    payload: service.logout1(),
  };
};

export const setUserLang = language => {
  return {
    type: SET_LANGUAGE,
    language,
  };
};

export const sendFeedback = data => {
  return {
    type: SEND_FEEDBACK,
    payload: axios.post('/restapi/accounts/feedback', data),
  };
};

export const accountsAvailable = () => {
  return {
    type: MULTIPLE,
    payload: axios.get('/restapi/public/accounts/multiple'),
  };
};

export const getCurrentUser = () => {
  let currentUser = null;

  try {
    currentUser = JSON.parse(localStorage.CURRENT_USER);
    if (!currentUser) {
      currentUser = null;
    }
  } catch (e) {
    currentUser = null;
  }

  if (!!currentUser) {
    return {
      type: SET_CURRENT_USER,
      currentUser: currentUser,
    };
  } else {
    return {
      type: DROP_CURRENT_USER,
    };
  }
};

export const setCurrentUser = currentUser => {
  return {
    type: SET_CURRENT_USER,
    currentUser: currentUser,
  };
};

export const dropCurrentUser = () => {
  return {
    type: DROP_CURRENT_USER,
  };
};

export const dropEditableUser = () => {
  return {
    type: DROP_EDITABLE_USER,
  };
};

export const checkToken = (user, token) => {
  return {
    type: CHECK_TOKEN,
    payload: axios.get(`/restapi/public/user/verify/${user}/${token}`),
  };
};

export const getUserAccount = (obj = {}) => {
  return {
    type: GET_USER_ACCOUNT,
    payload: axios.get(`/restapi/billing/accountTariff/wastedState`, { ...obj }),
  };
};
export const getUserSubscription = accountId => {
  const accountsadminService = new AccountsadminService(axios);
  return {
    type: GET_USER_SUBSCRIPTION,
    payload: accountsadminService.getPaymentSubscription(accountId),
  };
};

export const changeSubscription = (accountId, obj = {}) => {
  const accountsadminService = new AccountsadminService(axios);

  return {
    type: CHANGE_USER_SUBSCRIPTION_STATUS,
    payload: accountsadminService.updatePaymentSubscription(accountId, obj),
  };
};

export const getPaymentData = (accountId, paymentId, options) => {
  const accountsadminService = new AccountsadminService(axios);
  return {
    type: GET_USER_ACCOUNT_PAYMENT_DATA,
    payload: accountsadminService.getPaymentStatusById(accountId, paymentId, options),
  };
};

export const blockCurrentUserTariff = () => {
  return {
    type: BLOCK_CURRENT_USER_TARIFF,
  };
};

export const getAvailableMinutesPackages = (accountId, obj) => {
  return {
    type: GET_AVAILABLE_MINUTES_PACKAGES,
    payload: axios.get(`/restapi/accountsadmin/accounts/${accountId}/billing/minutePackagesV2`, { ...obj }),
  };
};

export const setPackagesCount = (name, value, isEnabled) => {
  return {
    type: SET_PACKAGES_COUNT,
    payload: {
      data: { value, name, isEnabled },
    },
  };
};

export const getVerificationCode = (data, useGCaptcha = false, gRecaptchaResponse) => {
  const options = {};
  if (useGCaptcha) {
    options.headers = { 'g-recaptcha-response': gRecaptchaResponse };
  }
  return {
    type: GET_VERIFICATION_CODE,
    payload: axios.post('/restapi/public/verification/phone/generate', data, options),
  };
};

export const checkVerificationCode = (data, number = null) => {
  return {
    type: CHECK_VERIFICATION_CODE,
    payload: axios._post('/restapi/public/verification/phone/verify', data, { number: number }),
  };
};

export const loginWithVerificationCode = (data, phone = null) => {
  return {
    type: CHECK_VERIFICATION_CODE,
    payload: axios.post('/restapi/public/verification/phone/verify', { ...data, phone }),
  };
};

export const requestPaymentByContract = data => {
  return {
    type: CONTRACT_REQUEST,
    payload: axios.post('/restapi/billing/contractRequest', data),
  };
};

export const uploadFile = file => {
  let fd = new FormData();
  fd.append('file', file);

  return {
    type: UPLOAD_FILE_TO_SERVER,
    payload: axios.post('/restapi/file/upload', fd),
  };
};

export const requestBotDevelopment = data => {
  return {
    type: CONTRACT_REQUEST,
    payload: axios.post('/restapi/accounts/developmentRequest', data),
  };
};

export const getCallsAvailability = (projectShortName = null) => {
  return {
    type: GET_CALLS_AVAILABILITY,
    payload: axios.get('/restapi/accounts/checkCallsAvailability', {
      params: {
        projectShortName,
      },
    }),
  };
};

export const checkUserPhoneCountryCode = () => {
  return {
    type: CHECK_USER_PHONE_COUNTRY_CODE,
    payload: axios.get('/restapi/accounts/checkUserPhoneCountryCode'),
  };
};

export const getAvailableSmsPackages = accountId => {
  return {
    type: GET_AVAILABLE_SMS_PACKAGES,
    payload: axios.get(`/restapi/accountsadmin/accounts/${accountId}/billing/smsPackagesV2`),
  };
};

export const buyAdditionalPackages = (packages, redirectUrl) => {
  return {
    type: BUY_ADDITIONAL_PACKAGES,
    payload: axios.post(`/restapi/billing/additionalPocket/pay`, {
      uniqueNames: packages,
      redirectUrl: redirectUrl,
    }),
  };
};

export const changeEmailByToken = changeEmailToken => {
  return {
    type: CHANGE_EMAIL_BY_TOKEN,
    payload: axios.post(`/restapi/users/changeEmailByToken`, { changeEmailToken }),
  };
};

export const sendInvoiceOffer = (data, userId) => {
  return {
    payload: axios.post(`/api/botadmin/users/${userId}/send-invoice-offer`, data),
  };
};

export const getInvoiceOfferData = userId => {
  return {
    payload: axios.get(`/api/botadmin/users/${userId}/invoice-offer`),
  };
};

export const getAvailableUniquesPackages = (accountId, obj) => {
  return {
    type: GET_AVAILABLE_UNIQUES_PACKAGES,
    payload: axios.get(`/restapi/accountsadmin/accounts/${accountId}/billing/uniquePackagesV2`, { ...obj }),
  };
};
/**
 * @typedef ManualControlData
 * @property {(boolean|undefined)} blockAfterPeriod - заблокировать по окончанию периода
 * @property {(string|undefined)} from - дата начала периода
 * @property {('MANUAL_CONTROL' | 'FREE' | undefined)} periodType - тип бесплатного периода
 * @property {(string|undefined)} tariff - тариф на время периода
 * @property {(string|undefined)} tariffAfterPeriod - тариф после периода
 * @property {(string|undefined)} tariffAfterPeriodName - название тарифа после периода
 * @property {(string|undefined)} tariffName - название тарифа на время периода
 * @property {(string|undefined)} to - дата окончания периода
 * @property {('TariffChangeStateDataPeriod' | 'TariffChangeStateDataMonthlyBilling')} type - тип периода
 */
/**
 * Получение данных о типе периода (оплата картой, бесплатный период, период по договору) и дат периода
 * @param {number} accountId - Id аккаунта
 * @returns ManualControlData
 */
export const getManualControlInfo = accountId => {
  return {
    type: GET_MANUAL_CONTROL_INFO,
    payload: axios.get(`/restapi/accountsadmin/accounts/${accountId}/billing/tariff/change-state-v2`),
  };
};

export const setCurrentUserPhone = phone => {
  return {
    type: SET_CURRENT_USER_PHONE,
    payload: {
      phone: phone,
    },
  };
};

export const setCurrentUserOnVerification = () => {
  return {
    type: SET_CURRENT_USER_ON_VERIFICATION,
  };
};

/**
 * @param {number} accountId
 * @return {{payload: Promise<GPTAIQuota>, type: string}}
 */
export const getGptStatus = accountId => {
  const chatGptApi = new GPTAIApi({}, '', axios);

  return {
    type: GET_GPT_STATUS,
    payload: chatGptApi.hasQuota(accountId).then(res => res.data),
  };
};
