import React from 'react';
import PropTypes from 'prop-types';
import localize from 'localization';
import { InputText, SwitchButton, JustSelect } from '@just-ai/just-ui';
import { withStyles } from 'altreidsds';
import { fetchWebim2Operators } from 'actions/channels.actions';
import BaseEditForm from '../BaseEditForm';
import classnames from 'classnames';
import classes from '../BaseEditForm/BaseEditForm.module.scss';

const { translate } = localize;

const styles = theme => ({
  select: {
    height: 58,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  bar: {},
});

class IncomingWebim2EditForm extends BaseEditForm {
  domain = '';
  email = '';
  password = '';
  operators = null;
  isGroupsExist = false;
  optionNoOperator = { value: -1, label: translate('Channels:Webim2:NoOperator') };

  constructor(props) {
    super(props);
    this.form = {};

    let needSendStartCommand = true;
    if (
      Boolean(props.editableChannel) &&
      Boolean(props.editableChannel.id) &&
      Boolean(props.editableChannel.customData)
    ) {
      if (props.editableChannel.customData.hasOwnProperty('needSendStartCommand')) {
        needSendStartCommand = props.editableChannel.customData.needSendStartCommand;
      }
    }

    this.state = {
      rollout: true,
      errors: [],
      choosedOperator: -1,
      needSendStartCommand,
    };
  }

  componentWillMount() {
    const { editableChannel } = this.props;
    this.setSubmitButtonDisabled(true);
    if (Boolean(editableChannel.customData)) {
      const { administratorEmail, administratorPassword, domain, operatorId } = editableChannel.customData;
      this.email = administratorEmail;
      this.password = administratorPassword;
      this.domain = domain;
      this.setState({ choosedOperator: operatorId || -1 });
      this.getGroups();
    }
  }

  createChannel = async () => {
    const { createChannel } = this.props;
    const { choosedOperator } = this.state;

    const channelData = {
      ...this.props.editableChannel,
      botName: this.form.name.value,
      senderName: this.form.name.value,
      accessToken: this.form.botToken.value,
      channelType: this.props.editableChannel.channelType,
      customData: {
        administratorEmail: this.email,
        administratorPassword: this.password,
        domain: this.domain,
        operatorId: choosedOperator === -1 ? null : choosedOperator,
        operatorGroupName: this.form.group?.value,
        needSendStartCommand: this.state.needSendStartCommand,
      },
      project: {
        ...this.props.editableChannel.project,
      },
    };

    await createChannel(channelData);

    this.props.onClose();
  };

  saveChannel = async () => {
    const { editChannel } = this.props;
    const { choosedOperator } = this.state;

    const channelData = {
      botName: this.form.name.value,
      senderName: this.form.name.value,

      accessToken: this.form.botToken.value,
      channelType: this.props.editableChannel.channelType,
      rollout: 'MANUAL',
      id: this.props.editableChannel.id,
      branch: 'master',
      customData: {
        ...editChannel.customData,
        administratorEmail: this.email,
        administratorPassword: this.password,
        domain: this.domain,
        operatorId: choosedOperator === -1 ? null : choosedOperator,
        operatorGroupName: this.form.group?.value,
        needSendStartCommand: this.state.needSendStartCommand,
      },
      project: {
        ...this.props.editableChannel.project,
      },
    };

    await editChannel(channelData);

    this.props.onClose();
  };

  handleEmailUpdate = value => {
    this.email = value;
    this.getGroupsDebounce();
  };

  handlePasswordUpdate = value => {
    this.password = value;
    this.getGroupsDebounce();
  };

  handleDomainUpdate = value => {
    this.domain = value;
    this.getGroupsDebounce();
  };

  getGroupsDebounce = () => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(this.getGroups, 500);
  };

  getGroups = async () => {
    if (!this.domain || !this.email || !this.password) return;

    try {
      const { payload } = fetchWebim2Operators({ domain: this.domain, password: this.password, email: this.email });
      const { data } = await payload;
      this.operators = [this.optionNoOperator, ...data.map(item => ({ label: item.email, value: item.id }))];

      this.setSubmitButtonDisabled(false);
      this.sortOperatorsByGroups(data);
      this.setState({ errors: [] });
    } catch (e) {
      this.setSubmitButtonDisabled(true);
      this.operators = null;
      this.isGroupsExists = false;
      this.setState({
        errors: [
          localize.translate(navigator.onLine ? 'Error:Webim2:WrongCredentials' : 'Error:Webim2:NoInternetConnection'),
        ],
      });
    }
  };

  sortOperatorsByGroups = () => (this.isGroupsExists = true);

  changeBotOperator = value => this.setState({ choosedOperator: Boolean(value[0]) ? Number(value[0]) : -1 });

  getLabelName = () => translate('Bot token');

  handleOperatorGroupName = value => {
    //because setState lagging
    this.form.group.value = value.replace(/[^a-z ]/gi, '');
  };

  toggleNeedSendStartCommand = value => {
    this.setState({ needSendStartCommand: value });
  };

  renderInputs() {
    const { editableChannel } = this.props;
    const { choosedOperator, needSendStartCommand } = this.state;

    return (
      <>
        <div className={classes.formControl}>
          <p className={classnames(classes.label, classes.labelRequired)}>{this.getLabelName()}</p>
          <InputText
            innerRef={input => (this.form.botToken = input)}
            id='bot_token'
            defaultValue={editableChannel.accessToken}
          />
        </div>
        <div className={classes.formControl}>
          <p className={classnames(classes.label, classes.labelRequired)}>{translate('Email')}</p>
          <InputText
            innerRef={input => (this.form.email = input)}
            id='bot_email'
            type='email'
            onChange={this.handleEmailUpdate}
            defaultValue={editableChannel.customData.administratorEmail}
          />
        </div>
        <div className={classes.formControl}>
          <p className={classnames(classes.label, classes.labelRequired)}>{translate('Password')}</p>
          <InputText
            innerRef={input => (this.form.password = input)}
            id='bot_password'
            onChange={this.handlePasswordUpdate}
            defaultValue={editableChannel.customData.administratorPassword}
          />
        </div>
        <div className={classes.formControl}>
          <p className={classnames(classes.label, classes.labelRequired)}>{translate('Domain')}</p>
          <InputText
            innerRef={input => (this.form.domain = input)}
            id='bot_domain'
            onChange={this.handleDomainUpdate}
            defaultValue={editableChannel.customData.domain}
          />
        </div>
        {this.isGroupsExists && (
          <div className={classes.formControl}>
            <p className={classes.label}>{translate('Department')}</p>
            <InputText
              innerRef={input => (this.form.group = input)}
              onChange={this.handleOperatorGroupName}
              id='bot_group'
              defaultValue={editableChannel.customData.operatorGroupName}
            />
          </div>
        )}
        {this.operators && (
          <div className={classes.formControl}>
            <p className={classes.label}>{translate('Channels:Webim2:Operator')}</p>
            <JustSelect
              value={choosedOperator}
              fullWidth
              getRef={input => (this.form.operator = input)}
              onChange={this.changeBotOperator}
              options={this.operators}
              position='fixed'
            />
          </div>
        )}

        <div className={classnames(classes.formControl, classes.switchContainer)}>
          <SwitchButton
            id='needSendStartCommand'
            value={needSendStartCommand}
            color='primary'
            onChange={this.toggleNeedSendStartCommand}
          />
          <label htmlFor='needSendStartCommand'>{translate('Send /start on dialogue start')}</label>
        </div>
      </>
    );
  }
}

IncomingWebim2EditForm.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(IncomingWebim2EditForm);
