import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import OAuthCallback from '../OAuthCallback';
import { OAuthBehaviour } from '../index';
export const OAuthContext = React.createContext({
    redirectUrl: '/oauth/callback',
});
export function useOAuthContext() {
    return useContext(OAuthContext);
}
export function useOAuthBehaviour() {
    const context = useContext(OAuthContext);
    return useMemo(() => new OAuthBehaviour(context.redirectUrl), [context]);
}
function CallbackWrapper({ children }) {
    const context = useContext(OAuthContext);
    return (_jsxs(Switch, { children: [_jsx(Route, { exact: true, path: context.redirectUrl, component: OAuthCallback }), ";", _jsx(Route, { render: () => children })] }));
}
const OAuthProvider = function ({ children }) {
    return (_jsx(OAuthContext.Provider, { value: {
            redirectUrl: '/oauth/callback',
        }, children: _jsx(CallbackWrapper, { children: children }) }));
};
export default OAuthProvider;
